@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
@tailwind base;
@tailwind components;
@tailwind utilities;

.q-dialog,
.q-loading {
    &__backdrop {
        will-change: opacity;
    }
}

.q-table--row-select {
    .q-table__top {
        position: sticky;
        top: $toolbar-min-height;
        background-color: #fff;
        border-bottom: 1px solid $separator-color;
        z-index: 1;
    }

    .col-select {
        max-width: 250px;
    }
}

.q-notification {
    width: 100%;
    max-width: 450px;

    &__message {
        font-weight: 500;
    }

    &__caption {
        @apply tw-mt-1 tw-leading-tight;
        font-weight: normal;
        opacity: 1;
    }

    &__actions {
        align-self: flex-start;
    }
}

.o-page {
    max-width: 900px;

    &--full {
        max-width: 100%;
    }
}

.o-form {
    max-width: 550px;
}

.o-dialog-form {
    width: 550px;
    max-width: 100%;
}

.q-field {
    &--drop-zone {
        .q-field__native {
            display: block;
            padding: 0;
        }
    }
}

@each $space, $value in $spaces {
    .u-unwrap-#{$space} {
        margin-left: -(map-get($value, 'x'));
        margin-right: -(map-get($value, 'x'));
    }
}

.u-rules {
    padding-bottom: 20px;
}

.u-text-sm {
    font-size: 12px;
}

.gm-style-mtc {
    > button:first-child {
        height: 40px;
    }
}

.boolean-circle {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    vertical-align: middle;
}

.boolean-square {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: #000;
}

.c-detailed-info {
    @apply tw-w-[300px] tw-fixed tw-top-[50px] tw-right-0 tw-bottom-0 tw-border-l tw-border-black/12 tw-bg-white tw-overflow-y-auto tw-z-[1001];

    // default sidebar transition
    &.default-enter-active,
    &.default-leave-active {
        transition: transform 0.12s;
    }

    &.default-enter-from,
    &.default-leave-to {
        transform: translateX(100%);
    }

    // show 2 level labels
    &.labels-enter-active {
        transition: transform 0.12s;
    }

    &.labels-enter-from {
        transform: translateX(100%);
    }

    // hide 2 level labels
    &.back-labels-leave-active {
        @apply tw-z-[1002];
        transition: transform 0.12s;
    }

    &.back-labels-leave-to {
        transform: translateX(100%);
    }
}
