@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

    .q-breadcrumbs {
        .q-router-link--active {
            transition: color 0.2s;

            &:hover {
                color: $dark;
            }
        }
    }
