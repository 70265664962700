
    .o-infinite-table {
        position: relative;

        &:after {
            content: '';
            display: block;
            position: absolute;
            left: -5px;
            right: -5px;
            bottom: -5px;
            height: 100px;
            background: linear-gradient(180deg, rgba(#fff, 0) 0%, rgba(#fff, 1) 95%);
            z-index: 1;
        }
    }
